<template>
  <div>
    <v-form ref="form">
      <div class="row mt-1">
        <input-select
          v-model="form.localExecucaoId"
          class="col-12 col-md-2"
          :label="$t('modulos.operacao.formulario.local_execucao')"
          :options="opcoes.localExecucao"
          :loading="loadingLocalExecucao"
          :obrigatorio="form.flagSemConserto == 6"
        />
        <input-text
          v-model="form.prazoEntregaEmDias"
          class="col-12 col-md-2"
          :label="$t('modulos.operacao.formulario.prazo_entrega')"
          type="number"
          :obrigatorio="form.flagSemConserto == 6"
        />
        <input-select
          v-model="form.flagSemConserto"
          class="col-12 col-md-2"
          :label="
            $t(
              'modulos.operacao.formulario.possivel_executar_concerto_instrumento'
            )
          "
          :options="opcoes.booleano"
          :obrigatorio="form.flagSemConserto == 6"
        />
        <input-select
          v-model="form.flagGarantia"
          class="col-12 col-md-2"
          :label="$t('modulos.operacao.formulario.garantia')"
          :options="opcoes.booleano"
          :obrigatorio="form.flagSemConserto == 6"
        />
        <input-text
          v-model="form.justificativaGarantia"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.justificativa_garantia')"
          :disabled="form.flagGarantia != 1"
        />
      </div>
      <div class="row mt-10">
        <div
          class="d-flex col-4"
          style="height: 25px; align-items: center"
        >
          {{ $t('modulos.operacao.formulario.calibracao') }}
          <v-switch v-model="form.flagTemServicoCalibracao" />
        </div>
        <div class="d-flex col-12">
          <div class="col-3">
            <input-select
              v-model="form.calibracao.tipoCalibracaoId"
              class="mb-4"
              :label="$t('modulos.operacao.formulario.tipo_calibracao')"
              :options="opcoes.tipoCalibracaoEnum"
              :disabled="!form.flagTemServicoCalibracao"
            />
            <input-text
              v-model="form.calibracao.pontosItens"
              :label="$t('modulos.operacao.formulario.pontos_itens')"
              type="number"
              :disabled="!form.flagTemServicoCalibracao || habilitaPontosItens"
              @change.native="atualizaPontosItens()"
            />
            <botao-padrao
              class="mt-5"
              @click="abreModalNovoPontoCalibracao"
            >
              {{ $t('modulos.operacao.formulario.novo_ponto_calibracao') }}
            </botao-padrao>
            <botao-padrao
              class="mt-5"
              @click="abreModalFaixa"
            >
              {{ $t('modulos.operacao.formulario.faixas') }}
            </botao-padrao>
          </div>
          <div class="col-9">
            <tabela-padrao
              v-model="tabelaCalibracao.selecionados"
              :dados="tabelaCalibracao.dados"
              :selecionar-apenas-um="true"
              class="mt-2"
              :mostrar-checkbox="form.flagTemServicoCalibracao"
              :colunas="tabelaCalibracao.colunas"
              :por-pagina="tabelaCalibracao.porPagina"
              :quantidade-paginas="tabelaCalibracao.quantidadePaginas"
              @paginar="listarServicosCalibracao"
            >
              <template v-slot:faixa="{ slotProps }">
                <div
                  v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
                  class="alinhar-direita"
                >
                {{
                  faixaFormatada(`(${ slotProps.data?.amplitudeMinima ? slotProps.data.amplitudeMinima + ' a ' : ''}
                ${ slotProps.data?.amplitudeMaxima || '...'})
                ${slotProps.data?.unidadeMedida?.simbolo || '' }`)
                }}
                </div>
              </template>
            </tabela-padrao>
          </div>
        </div>
      </div>
      <accordion-padrao
        :titulo="$t('modulos.operacao.formulario.servicos_manutencao')"
        class="accordion-padrao mt-2"
        iniciar-retraido
      >
        <div class="row">
          <div class="col-12">
            <span style="font-weight: 300">
              {{
                $t(
                  'modulos.operacao.formulario.selecione_servico_deseja_executar'
                )
              }}
            </span>
            <tabela-padrao
              v-model="tabelaManutencao.selecionados"
              :dados="tabelaManutencao.dados"
              :mostrar-checkbox="form.flagSemConserto == 1"
              :colunas="tabelaManutencao.colunas"
              :por-pagina="tabelaManutencao.porPagina"
              :quantidade-paginas="tabelaManutencao.quantidadePaginas"
              @paginar="listarServicosCalibracao"
            />
          </div>
        </div>
      </accordion-padrao>
      <accordion-padrao
        :titulo="$t('modulos.operacao.tabela.pecas')"
        class="accordion-padrao mt-2"
        iniciar-retraido
      >
        <div class="row">
          <div class="col-12">
            <span style="font-weight: 300">
              {{
                $t(
                  'modulos.operacao.formulario.selecione_servico_deseja_executar'
                )
              }}
            </span>
            <tabela-padrao
              v-model="tabelaPecas.selecionados"
              :dados="tabelaPecas.dados"
              :mostrar-checkbox="form.flagSemConserto == 1"
              :colunas="tabelaPecas.colunas"
              :por-pagina="tabelaPecas.porPagina"
              :quantidade-paginas="tabelaPecas.quantidadePaginas"
              @paginar="listarServicosCalibracao"
            >
              <!-- eslint-disable vue/valid-v-slot-->
              <template v-slot:faixa="{ slotProps }">
                <div
                  v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
                  class="alinhar-direita"
                >
                  {{
                    `(${
                      slotProps.data?.amplitudeMinima ? slotProps.data.amplitudeMinima + ' a ' : ''
                    }${
                      slotProps.data?.amplitudeMaxima || '...'
                    }) ${
                      slotProps.data?.unidadeMedida?.simbolo || ''
                    }`
                  }}
                </div>
              </template>
            </tabela-padrao>
          </div>
        </div>
      </accordion-padrao>

      <novo-ponto-calibracao-modal
        ref="modal-novo-ponto-calibracao"
        :instrumento-id="instrumentoId"
        :nome-tipo-instrumento="nomeTipoInstrumento"
        :codigo-instrumento="codigoInstrumento"
      />
      <faixa-modal
        ref="modal-faixa"
        :form="formInstrumento"
        @atualizar-intrumento-faixas="atualizarInstrumentoFaixas"
      />
    </v-form>
  </div>
</template>
<script>
import UtilsService from '@common/services/UtilsService';
import ItemService from '@common/services/cadastros/ItemService';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';
import OmieService from '@common/services/cadastros/OmieService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
// import ContratoService from '@common/services/cadastros/ContratoService';
import NovoPontoCalibracaoModal from './modais/NovoPontoCalibracaoModal.vue';
import FaixaModal from './modais/FaixaModal.vue';
import { InstrumentoModel } from '@common/models/cadastros/InstrumentoModel';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import helpers from '@/common/utils/helpers';
export default {
  components: { NovoPontoCalibracaoModal, FaixaModal },
  props: {
    form: { type: Object, default: () => ({}) },
    prazoGarantia: { type: Number, default: null },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
  },
  data() {
    return {
      formInstrumento: null,
      precoServicoContrato: null,
      codigoString: '',
      habilitaPontosItens: true,
      loadingTipoCalibracao: false,
      loadingLocalExecucao: false,
      opcoes: {
        localExecucao: [],
        tipoCalibracaoEnum: [],
        booleano: [
          {
            text: 'Sim',
            value: 1,
          },
          {
            text: 'Não',
            value: 2,
          },
        ],
      },
      tabelaCalibracao: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'codigo',
            text: this.$t('modulos.operacao.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.operacao.tabela.nome'),
            sortable: true,
          },
          {
            value: 'faixa',
            text: this.$t('modulos.operacao.tabela.faixa'),
            sortable: true,
            formatter: (valor, linha) => {
              if (linha && linha.unidadeMedida)
                return `${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMinima
                )} a ${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMaxima
                )} ${linha.unidadeMedida.nome}`;
            },
          },
          {
            value: 'minimoPontos',
            text: this.$t('modulos.operacao.tabela.minimo_pontos'),
            sortable: true,
          },
          {
            value: 'tempoExecucao',
            text: this.$t('modulos.operacao.tabela.tempo_execucao'),
            sortable: true,
          },
          {
            value: 'codigoContrato',
            text: this.$t('modulos.operacao.tabela.codigo_contrato'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      tabelaManutencao: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'tipoCalibracao.nome',
            text: this.$t('modulos.operacao.tabela.tipo_servico'),
            sortable: true,
            width: '200px',
          },
          {
            value: 'codigo',
            text: this.$t('modulos.operacao.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.operacao.tabela.nome'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      tabelaPecas: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'codigo',
            text: this.$t('modulos.operacao.tabela.codigo'),
            sortable: true,
            width: '250px',
          },
          {
            value: 'descricao',
            text: this.$t('modulos.operacao.tabela.descricao'),
            sortable: true,
          },
          {
            value: 'participanteFabricante.nome',
            text: this.$t('modulos.operacao.tabela.fabricante'),
            sortable: true,
          },
          {
            value: 'quantidadeEstoque',
            text: this.$t('modulos.operacao.tabela.quantidade_estoque'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    'form.calibracao.tipoCalibracaoId'(valor) {
      if (valor) this.listarServicosCalibracao();
      else {
        this.tabelaCalibracao.selecionados = [];
        this.tabelaCalibracao.dados = [];
      }
    },
    'form.flagSemConserto'(valor) {
      if (valor == 2) {
        this.form.flagTemServicoCalibracao = false;
        this.$refs.form.reset();
        this.tabelaCalibracao.selecionados = [];
        this.tabelaManutencao.selecionados = [];
      }
    },
    'form.localExecucaoId'() {
      if (
        this.tabelaCalibracao.selecionados.length ||
        this.tabelaManutencao.selecionados.length
      ) {
        this.confirmarTrocaLocal().then(() => {
          this.tabelaCalibracao.selecionados = [];
          this.tabelaManutencao.selecionados = [];
        });
      }
    },
    async 'tabelaCalibracao.selecionados'() {
      if (this.tabelaCalibracao.selecionados.length) {
        this.form.calibracao.servicoId =
          this.tabelaCalibracao.selecionados[0].id;

        // await this.validaPrecoServico(this.form.calibracao.servicoId);

        this.form.calibracao.valor = this.precoServicoContrato
          ? this.precoServicoContrato
          : this.tabelaCalibracao.selecionados[0].valorServico;

        this.form.calibracao.minimoPontos =
          this.tabelaCalibracao.selecionados[0].minimoPontos;
        this.form.calibracao.valorPonto =
          this.tabelaCalibracao.selecionados[0].valorPonto;

        this.tabelaCalibracao.selecionados.map((item) => {
          if (item.valorPonto > 0) {
            this.habilitaPontosItens = false;
          } else {
            this.habilitaPontosItens = true;
            this.form.calibracao.pontosItens = null;
          }
        });
      }
      this.$emit('valida-calibracao');
    },
    'tabelaPecas.selecionados'() {
      this.form.pecas = [];
      if (this.tabelaPecas.selecionados.length) {
        this.tabelaPecas.selecionados.map((itemPeca) => {
          this.form.pecas.push({
            item: {
              id: itemPeca.id,
              valor: itemPeca.valorVenda,
            },
          });
        });
      }
    },
    'tabelaManutencao.selecionados'() {
      this.form.manutencoes = [];
      if (this.tabelaManutencao.selecionados.length) {
        this.tabelaManutencao.selecionados.map((itemManutencao) => {
          this.form.manutencoes.push({
            servico: {
              id: itemManutencao.id,
              tempoExecucao: itemManutencao.tempoExecucao,
              horasPrevistas: itemManutencao.horasPrevistas,
              valor: itemManutencao.valorServico,
            },
          });
        });
      }
    },
  },
  mounted() {
    this.listarLocalExecucao();
    this.listarTipoCalibracao();
    this.listarServicosManutencao();
    this.listarPecas();
    this.listarDadosPadrao();
    this.form.flagSemConserto = 1;
    this.form.flagGarantia = 2;
  },
  methods: {
    abreModalNovoPontoCalibracao() {
      this.$refs['modal-novo-ponto-calibracao'].abrirModal();
    },
    //Endpoint desapareceu do back, aguardar definição
    // async validaPrecoServico(servicoId) {
    //   this.precoServicoContrato = null;

    //   let params = {
    //     participanteId: this.participanteId,
    //     instrumentoId: this.instrumentoId,
    //     servicoId: servicoId,
    //   };

    //   await ContratoService.buscarServicoContrato(params)
    //     .then((res) => {
    //       if (res.data) {
    //         this.precoServicoContrato = res.data.valorServico;
    //       }
    //     })
    //     .catch(() => {
    //       this.toastAlerta(
    //         this.$t(`geral.erros.instrumento_contrato_associado`)
    //       );
    //     });
    // },
    atualizaPontosItens() {
      this.$emit('valida-calibracao');
    },
    atualizaHorasPrevistas(itemManutencao) {
      if (this.tabelaManutencao.selecionados.length) {
        this.tabelaManutencao.selecionados.map((item) => {
          if (item.id == itemManutencao.item.id) {
            this.form.manutencoes.map((manutencao) => {
              if (manutencao.servico.id == itemManutencao.item.id) {
                manutencao.servico.horasPrevistas =
                  itemManutencao.item.horasPrevistas;
              }
            });
          }
        });
      }
    },
    listarDadosPadrao() {
      UtilsService.buscarDadosPadrao(this.id)
        .then((res) => {
          this.form.prazoEntregaEmDias = res.data.prazoEntregaPadrao;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.orcamentos.erros.id_invalido'));
        });
    },
    listarPecas() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ItemService.buscarPecas({flagPeca: true}, '')
        .then((res) => {
          this.tabelaPecas.dados = res.data.items;
          this.tabelaPecas.quantidadeItens = res.data.totalItems;
          this.tabelaPecas.quantidadePaginas = res.data.totalPages;
          this.tabelaPecas.paginaAtual = 1;
          this.tabelaPecas.porPagina = 10;

          this.codigoString = '';
          if (res.data.items.length) {
            res.data.items.map((item) => {
              this.codigoString += `&Codigos=${item.codigo}`;
            });
          }

          this.montaListagemPecas(this.codigoString);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    montaListagemPecas(codigoString) {
      let parametros = {
        flagFiltrarCodigos: true,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      OmieService.buscaDadosOmie(codigoString, parametros)
        .then((res) => {
          this.tabelaPecas.dados.map((item) => {
            if (res.data.produtos.length) {
              res.data.produtos.map((itemProduto) => {
                if (item.codigo === itemProduto.codigo)
                  item.quantidadeEstoque = itemProduto.saldo;
              });
            }
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarServicosCalibracao() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      UtilsService.listarTipoServicos({}, 1)
        .then((res) => {
          this.tabelaCalibracao.dados = res.data.items;
          this.tabelaCalibracao.quantidadeItens = res.data.totalItems;
          this.tabelaCalibracao.quantidadePaginas = res.data.totalPages;
          this.tabelaCalibracao.paginaAtual = 1;
          this.tabelaCalibracao.porPagina = 10;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarServicosManutencao() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      UtilsService.listarTipoServicos({}, 2)
        .then((res) => {
          this.tabelaManutencao.dados = res.data.items;
          this.tabelaManutencao.dados.map((item) => {
            if (item.tempoExecucao == 5) item.horasPrevistas = 5;
            else item.horasPrevistas = item.tempoExecucao;
          });
          this.tabelaManutencao.quantidadeItens = res.data.totalItems;
          this.tabelaManutencao.quantidadePaginas = res.data.totalPages;
          this.tabelaManutencao.paginaAtual = 1;
          this.tabelaManutencao.porPagina = 10;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarLocalExecucao() {
      this.loadingLocalExecucao = true;
      UtilsService.buscaLocalExecucao()
        .then((res) => {
          this.opcoes.localExecucao = new DropdownModel(res.data);
        })
        .finally(() => {
          this.loadingLocalExecucao = false;
        });
    },
    listarTipoCalibracao() {
      this.loadingTipoCalibracao = true;
      TipoCalibracaoService.listar()
        .then((res) => {
          this.opcoes.tipoCalibracaoEnum = new DropdownModel(res.data);
        })
        .finally(() => {
          this.loadingTipoCalibracao = false;
        });
    },
    async abreModalFaixa() {
      await InstrumentoService.buscar(this.instrumentoId).then((res) => {
        this.formInstrumento = new InstrumentoModel(res.data);
      });
      await this.$refs['modal-faixa'].abrirModal();
    },
    atualizarInstrumentoFaixas: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.salvar(this.formInstrumento.request)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.confirmar_atualizacao_faixa`)
          );
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    faixaFormatada: function (value) {
      return String(value).replaceAll('.', ',');
    }
  },
};
</script>
