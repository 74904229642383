<template lang="">
  <modal-padrao
    ref="modal-faixa"
    max-width="90%"
    :titulo="`${$t('modulos.instrumento.tabela.faixas')} - Instrumento: ${form?.codigoTag}`"
    :titulo-ok="$t('geral.botoes.salvar')"
    :mostrar-botoes="false"
  >
    <faixa
      class="mb-5"
      :aberto-como-modal="true"
      :form="form"
    />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="fecharModal"
      >
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        @click="confirmarModal"
      >
        {{ $t('geral.botoes.confirmar') }}
      </botao-padrao>
    </div>
  </modal-padrao>
</template>
<script>
import Faixa from '@views/modulos/cadastros/instrumento/components/Faixas'
export default {
  components: { Faixa },
  props: {
    form: {type: Object, default: () => ({})}
  },
  data() {
    return {
      indiceAba: 0,
    };
  },
  methods: {
    abrirModal() {
      this.$refs['modal-faixa'].abrirModal();
    },
    fecharModal() {
      this.$refs['modal-faixa'].fecharModal();
    },
    confirmarModal() {
      this.$emit('atualizar-intrumento-faixas', this.form)
      this.$refs['modal-faixa'].fecharModal();
    },
  },
};
</script>
