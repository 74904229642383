<template>
  <div>
    <div class="row d-flex mb-8">
      <div class="titulo-pagina col-12 col-md-9">
        Análise Técnica - Instrumento:{{ codigoInstrumento }} -
        {{ nomeTipoInstrumento }} - OS:{{ codigoOrdemServico }}
      </div>
      <div class="titulo-pagina col-12 col-md-3">
        <div class="d-flex" style="place-content: space-between">
          <div>{{ $t('modulos.operacao.formulario.valor_total') }}</div>
          <div style="color: #039855">{{ percentualProgressBar() }}%</div>
        </div>
        <v-progress-linear
          :value="percentualProgressBar()"
          height="20"
          background-color="#E4E7EC"
          color="#039855"
        />
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.operacao.formulario.abas.analise_tecnica') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.operacao.formulario.abas.requisitos') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <analise-tecnica
          ref="analise-tecnica"
          :form="form"
          :participante-id="participanteId"
          :instrumento-id="instrumentoId"
          :nome-tipo-instrumento="nomeTipoInstrumento"
          :codigo-instrumento="codigoInstrumento"
          @valida-calibracao="validaCalibracao"
        />
      </v-tab-item>
      <v-tab-item>
        <requisitos
          :form="form"
          :requisitos-cliente="dadosRequisitosClienteFormatado"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="salvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import AnaliseTecnicaService from '@common/services/cadastros/AnaliseTecnicaService';
import AnaliseTecnica from './components/AnaliseTecnica';
import { AnaliseTecnicaModel } from '@common/models/cadastros/AnaliseTecnicaModel';
import OrdemServicoService from '@common/services/cadastros/OrdemServicoService';
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import { Requisitos } from '@components/requisitos';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import helpers from '@common/utils/helpers';
export default {
  components: {
    AnaliseTecnica,
    Requisitos,
  },
  props: ['id', 'ordemServico'],
  data() {
    return {
      valorDoIntrumento: 0,
      codigoOrdemServico: '',
      codigoInstrumento: '',
      nomeTipoInstrumento: '',
      dadosRequisitosClienteFormatado: null,
      valorTotal: 0,
      valido: false,
      prazoEntregaPadrao: null,
      participanteId: null,
      instrumentoId: null,
      indiceAba: 0,
      form: new AnaliseTecnicaModel({
        calibracao: {},
        manutencoes: [],
        pecas: [],
      }),
    };
  },
  watch: {
    form: {
      handler() {
        this.calculaValorTotal();
      },
      deep: true,
    },
  },
  mounted() {

    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'Operacao', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'Operacao', 'Inserir');
    this.form.flagTemServicoCalibracao = true;
    this.buscaDadosInstrumento();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.operacao.titulos.analise_tecnica')
    );
  },
  methods: {
    dadosRequisitos: function (dados) {
      this.dadosRequisitosClienteFormatado = dados;
    },
    calculaValorTotal() {
      this.valorTotal = 0;

      if (this.form.calibracao?.valor) {
        this.form.calibracao.pontosItens = this.form.calibracao.pontosItens
          ? parseFloat(this.form.calibracao.pontosItens)
          : 0;

        if (
          this.form.calibracao.minimoPontos <
            this.form.calibracao.pontosItens &&
          this.form.calibracao.valorPonto
        ) {
          this.valorTotal =
            (this.form.calibracao.pontosItens -
              this.form.calibracao.minimoPontos) *
            this.form.calibracao.valorPonto;
        }

        this.valorTotal += this.form.calibracao.valor;
      }

      if (this.form.manutencoes.length) {
        this.form.manutencoes.map((item) => {
          if (item.servico?.valor) this.valorTotal += item.servico.valor;
        });
      }

      if (this.form.pecas.length) {
        this.form.pecas.map((item) => {
          if (item.item?.valor) this.valorTotal += item.item.valor;
        });
      }

      this.percentualProgressBar();
    },
    buscaDadosInstrumento() {
      OrdemServicoService.buscar(this.ordemServico)
        .then((res) => {
          if (res.data) {
            this.codigoOrdemServico = res.data.codigo;
            this.participanteId = res.data.solicitanteParticipanteId;
            this.buscaRequisitos(res.data.clienteParticipanteOrigemId);
            this.instrumentoId =
              res.data.ordensServicosInstrumentos[0].instrumentoId;

            res.data.ordensServicosInstrumentos.map((item) => {
              if (item.instrumentoId == this.id) {
                this.buscaValorInstrumento(item.instrumentoId);
                this.codigoInstrumento = item.codigoTag;
                this.nomeTipoInstrumento = item.tipoInstrumento.nome;
              }
            });

            if (res.data.localExecucaoId)
              this.form.localExecucaoId = res.data.localExecucaoId;
          }
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.orcamentos.erros.id_invalido'));
        });
    },
    buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscaRequisitosParticipante(id, 'tipoRequisito=2')
        .then((res) => {
          if (res?.data) this.dadosRequisitos(res?.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscaValorInstrumento(id) {
      InstrumentoService.buscar(id)
        .then((res) => {
          if (res.data) {
            this.valorDoIntrumento = res.data.valorInstrumento;
          }
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.orcamentos.erros.id_invalido'));
        });
    },
    validacaoAanaliseTecnica() {
      if (this.$refs['analise-tecnica'])
        this.valido = this.$refs['analise-tecnica'].$refs.form.validate();

      if (
        !this.form.calibracao?.servicoId &&
        !this.form.manutencoes.length &&
        !this.form.pecas.length
      )
        this.valido = false;
      else if (this.$refs['analise-tecnica'].$refs.form.validate())
        this.valido = true;

      if (this.form.flagSemConserto == 2) this.valido = true;

      return this.valido;
    },
    salvar() {
      if (!this.validacaoAanaliseTecnica()) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.form.valorTotal = this.valorTotal;
      this.form.instrumentoId = this.id;
      this.form.ordemServicoId = this.ordemServico;
      if (this.form.flagSemConserto == 1) this.form.flagSemConserto = false;
      if (this.form.flagSemConserto == 2) {
        this.form.flagSemConserto = true;
        this.form.calibracao = null;
      }
      if (this.form.flagGarantia == 1) this.form.flagGarantia = true;
      if (this.form.flagGarantia == 2) this.form.flagGarantia = false;
      AnaliseTecnicaService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.analise_tecnica.salvo_com_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    validaCalibracao() {
      this.calculaValorTotal();

      if (
        this.form.calibracao?.servicoId &&
        this.$refs['analise-tecnica'].$refs.form.validate()
      )
        this.valido = true;
    },
    percentualProgressBar() {
      let percentualTotal = 0;
      if (this.valorTotal) {
        percentualTotal = Number(
          (this.valorTotal / this.valorDoIntrumento) * 100
        ).toFixed(3);
      } else this.percentualTotal = 0;

      return Math.trunc(percentualTotal);
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'operacao' });
      });
    },
  },
};
</script>
<style>
.v-progress-linear {
  border-radius: 12px;
}
</style>
